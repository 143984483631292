// Generated by Framer (0b8b5e5)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {YXPtPYUKn: {hover: true}};

const serializationHash = "framer-Hs6ph"

const variantClassNames = {YXPtPYUKn: "framer-v-dokcb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "YXPtPYUKn", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://calendly.com/genflows/consultancy"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-dokcb", className, classNames)} framer-1dj5oy3`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"YXPtPYUKn"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-3d897b73-81ff-4693-b581-bee4102afd93, rgb(255, 111, 0))", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, boxShadow: "none", ...style}} variants={{"YXPtPYUKn-hover": {boxShadow: "0px 4px 10px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 0px 0px 4px rgba(255, 111, 0, 0.25)"}}} {...addPropertyOverrides({"YXPtPYUKn-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7TW9udHNlcnJhdC1zZW1pYm9sZA==", "--framer-font-family": "\"Montserrat\", \"Montserrat Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.03em", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>FREE Consultancy</motion.p></React.Fragment>} className={"framer-1crcaoe"} data-framer-name={"Consultancy"} fonts={["FS;Montserrat-semibold"]} layoutDependency={layoutDependency} layoutId={"Y15aO_v_2"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"center"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Hs6ph.framer-1dj5oy3, .framer-Hs6ph .framer-1dj5oy3 { display: block; }", ".framer-Hs6ph.framer-dokcb { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 40px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 190px; will-change: var(--framer-will-change-override, transform); }", ".framer-Hs6ph .framer-1crcaoe { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Hs6ph.framer-dokcb { gap: 0px; } .framer-Hs6ph.framer-dokcb > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-Hs6ph.framer-dokcb > :first-child { margin-top: 0px; } .framer-Hs6ph.framer-dokcb > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 190
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QPLD52Os_":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpuZGiczZA: React.ComponentType<Props> = withCSS(Component, css, "framer-Hs6ph") as typeof Component;
export default FramerpuZGiczZA;

FramerpuZGiczZA.displayName = "Button/Primary";

FramerpuZGiczZA.defaultProps = {height: 40, width: 190};

addFonts(FramerpuZGiczZA, [{explicitInter: true, fonts: [{family: "Montserrat", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/DELXRVKO253LHPYOBD6KD7EW3TJKXCXF/L6SILXET3P727LN5N75LL5PG77IET2IT/6DP3DAT2N5LSQGN5ISPRN63WPP32A54A.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})